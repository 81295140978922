import * as React from "react";
import { push } from "gatsby";
import { LoginForm, LoginFooter } from "@fitplan/lib/components/UserAuth/LoginForm";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import { LoginFormStrings } from "../components/strings/LoginFormStrings";

export interface Props {}

const Login: React.SFC<Props> = (props) => {
    return (
        <PageWrapper {...props}>
            <LoginFormStrings>
                <LoginForm onComplete={(user) => {
                    push("/account");
                }} />
                <LoginFooter />
            </LoginFormStrings>
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

export default Login;
